import { __rest } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { clearPhone, formatPhone } from '../../PhoneInput/utils';
import Dropdown from '../../Dropdown/components/Dropdown';
import Input from '../../Input';
const PhoneDropdown = (_a) => {
    var { options, InputProps, onChange, onInputChange } = _a, props = __rest(_a, ["options", "InputProps", "onChange", "onInputChange"]);
    const [value, setValue] = useState(props.value || '');
    const renderInput = useCallback((params) => React.createElement(Input, Object.assign({}, params, InputProps)), [
        InputProps
    ]);
    const onChangeHandler = (_, value) => {
        onChange(typeof value === 'string' ? value : value.value);
    };
    const onInputChangeHandler = (_, newValue) => {
        const phoneValue = formatPhone(newValue);
        if (value !== phoneValue) {
            setValue(formatPhone(phoneValue));
            onInputChange === null || onInputChange === void 0 ? void 0 : onInputChange(clearPhone(newValue));
        }
    };
    return (React.createElement(Dropdown, Object.assign({ freeSolo: true, forcePopupIcon: true, disableClearable: true, renderInput: renderInput, inputValue: value, options: options.map(value => ({ label: formatPhone(value), value })), onChange: onChangeHandler, onInputChange: onInputChangeHandler }, props)));
};
export default PhoneDropdown;
