import { __awaiter } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, useTheme } from '../../../theme';
import { useInsuranceGroupTexts, useInsuranceUpsale } from './hooks';
import { Money } from '../../../Money';
import { Included } from '../../../Icons';
import { useRenderers } from '../../../renderProps';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import cn from 'classnames';
import { AdditionalServiceType } from '../../types';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import Modal from '../../../Modal';
import { InsurancePageModal, InsuranceUpsaleModal } from '../../../InsurancePage';
import SimpleLoader from '../../../SimpleLoader';
const InsuranceUpsaleAggregator = ({ insurancePrograms, oldVersion, groupName }) => {
    var _a;
    const { t } = useTranslation('Checkout');
    const { InsuranceUpsale: theme, Insurance: cardStyles } = useTheme('CheckoutAdditionalService');
    const model = useInsuranceUpsale(insurancePrograms);
    const groupTexts = useInsuranceGroupTexts(groupName, insurancePrograms);
    const onServiceDelete = (_, service) => __awaiter(void 0, void 0, void 0, function* () {
        const insuranceService = service;
        yield model.onDeleteInsurance(insuranceService.code);
    });
    const renderPriceDescription = (isSelected) => {
        return isSelected ? (model.totalPrice.amount > 0 && (React.createElement("div", { className: theme.price },
            React.createElement("span", { className: theme.price__label }, t('Total')),
            React.createElement(Money, { moneyClassName: theme.money, money: model.totalPrice }),
            React.cloneElement(Included, { className: theme.includedIcon })))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: theme.forAll }, t('For all passengers')),
            React.createElement("div", { className: theme.price },
                model.isDifferentPrices && React.createElement("span", { className: theme.price__label }, t('from')),
                React.createElement(Money, { moneyClassName: theme.money, money: model.minPrice }))));
    };
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    const buttons = React.useMemo(() => {
        return [
            {
                text: model.singleInsurance ? t('Remove protection') : t('Change'),
                action: model.singleInsurance ? model.onDeleteSingle : model.open,
                className: cn({
                    [theme.loading]: model.isLoading
                })
            }
        ];
    }, [model.isLoading, model.singleInsurance]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InsuranceSummary, { flight: model.order.flight, travellers: model.order.travellers, services: model.insuranceSummaryServices, onServiceDelete: model.totalPrice.amount > 0 ? onServiceDelete : null, isConfirmed: model.isConfirmed, isDownload: true }, items => {
            const isSelected = items.length > 0;
            return (React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option: cardStyles.selectedOption,
                            option__name: cardStyles.option__name
                        }
                    }
                } },
                React.createElement(ServiceWrapper, { header: groupTexts.title, renderHeaderPrice: () => renderPriceDescription(isSelected), serviceType: AdditionalServiceType.Insurance, className: cardStyles.insurance, coverClassName: cardStyles.insurance_aggregator, isLoading: model.isLoading, isSelected: isSelected, priceFrom: model.minPrice, priceTotal: model.totalPrice, mode: "aggregator", description: React.createElement("div", null,
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: groupTexts.descr } }),
                        React.createElement("ul", null, groupTexts.options.map(option => (React.createElement("li", { key: option, dangerouslySetInnerHTML: { __html: option } }))))), addClick: () => model.open(), buttons: buttons },
                    model.order.travellers.length > 1 && (React.createElement("div", { className: cn(theme.label, theme.label_aggregator) }, t('For all passengers'))),
                    React.createElement(ServiceSummaryDivider, null,
                        React.createElement(ServiceSummaryOption, { groupOption: { items } })))));
        }),
        React.createElement(Modal, { classes: { scrollBody: theme.scrollBody, paper: theme.paper }, open: model.isOpen, fullScreen: true },
            React.createElement(InsurancePageModal, { availablePrograms: model.availablePrograms, onServicesChanged: model.setIsNeedToSendSaveReq, onTotalInsurancePriceChange: model.setInsurancePriceToPay, onSaveOrderServicesParamsChange: model.setSaveOrderServicesParams, onDownloadNasInsurance: model.onDownloadVskInsurance, totalPrice: model.insurancePriceToPay, onConfirm: model.onConfirm, onClose: model.onClose }),
            model.isLoading && React.createElement(SimpleLoader, null)),
        React.createElement(InsuranceUpsaleModal, { insurance: model.priorityInsurance, isOpen: model.isUpsaleOpen, onAgree: model.handleAgree, onDisagree: model.handleDisagree })));
};
export default InsuranceUpsaleAggregator;
