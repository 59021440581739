import * as React from 'react';
import { useSelector } from 'react-redux';
import { getInsurancesByGroups } from '../../../store/order/selectors';
import InsuranceUpsale from '../../../../CheckoutAdditionalService/components/InsuranceUpsale/InsuranceUpsale';
import InsuranceUpsaleAggregator from '../../../../CheckoutAdditionalService/components/InsuranceUpsale/InsuranceUpsaleAggregator';
const OrderInsuranceBlockSlotUpsale = ({ aggregatorMode = false }) => {
    const insurancesByGroups = useSelector(getInsurancesByGroups);
    const Insurance = aggregatorMode ? InsuranceUpsaleAggregator : InsuranceUpsale;
    return (React.createElement(React.Fragment, null, insurancesByGroups.map((insurances, index) => (React.createElement(Insurance, { key: index, groupName: insurances.groupName, insurancePrograms: insurances.insurancePrograms, oldVersion: true })))));
};
export default OrderInsuranceBlockSlotUpsale;
