import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CardExtrasProvider } from './CardExtras.context';
import { getCheckinOrder, getAdditionalServices, getTravellers, getDeprecatedPassengersState } from '../Modules/Checkin/store/order/selectors';
import { checkoutSelectedServicesStateFactory } from '../Checkout/utils';
import { useOrderServices } from '../Modules/Checkin/hooks';
const CheckinCardExtrasProvider = ({ children }) => {
    const order = useSelector(getCheckinOrder);
    const travellers = useSelector(getTravellers);
    const additionalServices = useSelector(getAdditionalServices);
    const passengers = useSelector(getDeprecatedPassengersState);
    const { flight, segments, selectedServices, brandIncludedBaggage } = useMemo(() => {
        return {
            flight: {
                seatMap: order.seatMap,
                segments: order.segments.map(segment => ({ segment: Object.assign(Object.assign({}, segment), { messages: [] }) })),
                segmentGroups: [],
                fares: []
            },
            segments: order.segments.map(segment => (Object.assign(Object.assign({}, segment), { messages: [] }))),
            selectedServices: checkoutSelectedServicesStateFactory(order),
            brandIncludedBaggage: []
        };
    }, [order]);
    const { isLoading, onSaveServicesRequest, onSaveServices, onAddServices, onRemoveServices } = useOrderServices();
    return (React.createElement(CardExtrasProvider, { value: {
            order,
            flight,
            segments,
            travellers,
            additionalServices,
            passengers,
            selectedServices,
            brandIncludedBaggage,
            isLoading,
            onSaveServicesRequest,
            onSaveServices,
            onAddServices,
            onRemoveServices
        } }, children));
};
export default CheckinCardExtrasProvider;
