import * as React from 'react';
import { addDays, parse } from 'date-fns';
import i18n from 'i18next';
import { AeroexpressDirection, AeroexpressClass } from '@websky/graphql';
import { format, getAllSegmentsServicePrice } from '../../../utils';
import { getActualProduct } from '../../../ServiceSummary/utils';
import { isBaggageService, isVipService } from '../../utils';
import BaggageIcon from '../../../Cart/BaggageIcon/BaggageIcon';
export const ROUND_TRIP_CLASSES = [
    AeroexpressClass.roundtrip,
    AeroexpressClass.roundTrip,
    AeroexpressClass.business_roundtrip,
    AeroexpressClass.couple_roundtrip,
    AeroexpressClass.family_roundtrip
];
export function isRoundTrip(trip) {
    var _a, _b;
    if (((_a = trip.segments) === null || _a === void 0 ? void 0 : _a.length) <= 1) {
        return false;
    }
    return ROUND_TRIP_CLASSES.includes(trip.class) || ((_b = trip.fromStation) === null || _b === void 0 ? void 0 : _b.direction) === AeroexpressDirection.Roundtrip;
}
export function getTripDirection(selectedTrip) {
    let direction;
    const fromDirection = selectedTrip.fromStation.direction;
    if (fromDirection === AeroexpressDirection.FromAirport) {
        direction = [i18n.t(`Aeroexpress:${fromDirection}`), i18n.t(`Aeroexpress:${selectedTrip.toStation.name}`)];
    }
    else if (fromDirection === AeroexpressDirection.ToAirport) {
        direction = [i18n.t(`Aeroexpress:${selectedTrip.fromStation.name}`), i18n.t(`Aeroexpress:${fromDirection}`)];
    }
    else {
        direction = [i18n.t(`Aeroexpress:${selectedTrip.fromStation.name}`)];
    }
    return direction.join(' – ');
}
export function getTripDatesLabel(trip) {
    const departureDate = parse(trip.departureDate, 'HH:mm dd.MM.yyyy', new Date());
    const departureFormatDate = format(departureDate, 'dd.MM.yyyy');
    if (isRoundTrip(trip)) {
        const dateMonthLaterDeparture = format(addDays(departureDate, 30), 'dd.MM.yyyy');
        return `${departureFormatDate} – ${i18n.t('Aeroexpress:To')} ${dateMonthLaterDeparture}`;
    }
    return departureFormatDate;
}
export function getTripFullLabel(trip) {
    return `${getTripDatesLabel(trip)} ${getTripDirection(trip)}, ${i18n.t(`Aeroexpress:${trip.class}`)}`;
}
export function getServiceOptionItem(key, type, selectedService, flight, allSegments, included, traveller) {
    var _a;
    let icon;
    let name = `${selectedService.count}x ${selectedService.service.name}`;
    let price = Object.assign({}, selectedService.service.price);
    const segment = flight.segments.find(segment => segment.segment.id === key);
    if (type === 'meal') {
        const direction = allSegments
            ? i18n.t('CardExtra:All flights')
            : `${segment.segment.departure.airport.iata} – ${segment.segment.arrival.airport.iata}`;
        name = `${direction}, ${name}`;
    }
    else if (isBaggageService(type)) {
        const baggageService = selectedService.service;
        name = `${name}, ${baggageService.baggageWeight}`;
        price.amount = baggageService.price.amount * selectedService.count - selectedService.confirmedCount;
        if (allSegments) {
            price.amount *= flight.segmentGroups.length;
        }
        if (type !== 'animal') {
            icon = React.createElement(BaggageIcon, { baggageType: baggageService.baggageType });
        }
    }
    else if (type === 'sms') {
        name = traveller.name;
        price = getAllSegmentsServicePrice(selectedService.service);
    }
    else if (isVipService(type)) {
        name = i18n.t(`CardExtra:ServiceName.${type}`);
    }
    if (included) {
        return {
            icon: icon && isBaggageService(type) ? icon : null,
            name,
            id: selectedService.service.id,
            included: true,
            segment: segment === null || segment === void 0 ? void 0 : segment.segment.id
        };
    }
    return {
        name,
        icon,
        id: selectedService.service.id,
        price,
        segment: key,
        status: (_a = getActualProduct(selectedService.products)) === null || _a === void 0 ? void 0 : _a.status,
        included: selectedService.count === selectedService.confirmedCount
    };
}
