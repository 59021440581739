import { useCallback, useMemo } from 'react';
import i18n from 'i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { TravellerFields } from '../../../../TravellerForm/types';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
import { getValueByName } from '../../../../CurrentUser/utils';
import { formatPhone } from '../../../../BaseComponents/PhoneInput/utils';
import { getFormTravellerKey } from '../utils';
import { getUserValue } from '../../../../utils';
export const useSmsPopupValidation = (regExp) => {
    const testPhone = (traveller, values) => {
        const cleanValue = clearPhoneNumber(traveller.phone);
        const validated = regExp.filter(rule => !new RegExp(rule.pattern).test(cleanValue));
        if (!traveller.phone) {
            return i18n.t('Contacts:Required');
        }
        if (validated.length) {
            return i18n.t(`Contacts:${validated[0].error}`);
        }
        if (Object.keys(values).some(key => values[key].selected &&
            values[key].id !== traveller.id &&
            clearPhoneNumber(values[key].phone) === cleanValue)) {
            return i18n.t(`Checkout:The phone numbers should not match`);
        }
        return undefined;
    };
    const validate = useCallback((values) => {
        const errors = {};
        for (const key in values) {
            if (values[key].selected) {
                errors[key] = {};
                errors[key].phone = testPhone(values[key], values);
            }
        }
        return errors;
    }, []);
    return {
        validate
    };
};
export const useSmsPopupFormData = (travellers, customerValues, smsServiceId) => {
    const initialFormData = useMemo(() => {
        const initialFormData = {};
        travellers.forEach(traveller => {
            var _a;
            const key = getFormTravellerKey(traveller.id);
            const selected = !!((_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.some(service => service.serviceId === smsServiceId));
            const phone = getValueByName(traveller.values, TravellerFields.Phone) || '';
            initialFormData[key] = {
                selected,
                phone: selected && phone ? formatPhone(phone) : '',
                id: traveller.id
            };
        });
        return initialFormData;
    }, [travellers]);
    const clearFormData = (form) => {
        form.batch(() => {
            Object.keys(initialFormData).forEach(key => {
                form.change(`${key}.phone`, '');
                form.change(`${key}.selected`, false);
            });
        });
    };
    const travellersPhoneOptions = useMemo(() => {
        const phoneSet = new Set();
        const customerPhone = getUserValue({ values: customerValues }, TravellerFieldEnum.Phone);
        if (customerPhone) {
            phoneSet.add(customerPhone);
        }
        travellers.forEach(traveller => {
            const travellerPhone = getUserValue(traveller, TravellerFieldEnum.Phone);
            if (travellerPhone) {
                phoneSet.add(travellerPhone);
            }
        });
        return [...phoneSet].map(clearPhoneNumber);
    }, [travellers, customerValues]);
    return {
        initialFormData,
        clearFormData,
        travellersPhoneOptions
    };
};
