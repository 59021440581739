import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriceGraph from './PriceGraph/PriceGraph';
import WeekCalendar from './WeekCalendar/WeekCalendar';
import { ThemeProvider, useTheme } from '../../../../theme';
import Modal from '../../../../Modal';
import { ButtonBase } from '@material-ui/core';
import OtherDates from '../OtherDates/OtherDates';
import FfpInfoNotice from '../../../../FfpInfoNotice';
import { useConfig } from '../../../../context';
var TabsList;
(function (TabsList) {
    TabsList[TabsList["Weekly"] = 0] = "Weekly";
    TabsList[TabsList["Monthly"] = 1] = "Monthly";
})(TabsList || (TabsList = {}));
var diagramIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 18V4H17V18H15ZM7 18H9V6H7V18ZM3 18H5V12H3V18ZM13 18H11V9H13V18ZM19 18H21V7H19V18Z", fill: "currentColor" })));
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 22H10L15 13.5789H20.5C21.33 13.5789 22 12.8737 22 12C22 11.1263 21.33 10.4211 20.5 10.4211H15L10 2H8L10.5 10.4211L5 10.4211L3.5 8.31579H2L3 12L2 15.6842H3.5L5 13.5789L10.5 13.5789L8 22Z", fill: "currentColor" })));
var Calendar = function (_a) {
    var _b, _c;
    var searchParameters = _a.searchParameters, currentLeg = _a.currentLeg, startNewSearch = _a.startNewSearch, searchMinPrice = _a.searchMinPrice, alternativePrices = _a.alternativePrices, ffpInfo = _a.ffpInfo, onRemoveCard = _a.onRemoveCard, onPayByMoney = _a.onPayByMoney, _d = _a.hidePrices, hidePrices = _d === void 0 ? false : _d, renderTitle = _a.renderTitle, allowSelectWithoutPrice = _a.allowSelectWithoutPrice;
    var t = useTranslation('Results').t;
    var _e = __read(useState(false), 2), isPriceGraphOpen = _e[0], setPriceGraphOpen = _e[1];
    var css = useTheme('Results').Calendar;
    var legId = currentLeg ? currentLeg.id : 0;
    var _f = __read(useState(TabsList.Weekly), 2), activeTab = _f[0], setActiveTab = _f[1];
    var payByMiles = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.SearchForm.payByMiles;
    useEffect(function () {
        if (activeTab === TabsList.Monthly) {
            setActiveTab(TabsList.Weekly);
            setPriceGraphOpen(true);
        }
    }, [activeTab]);
    var handleModalClose = useCallback(function () {
        setPriceGraphOpen(false);
    }, [activeTab]);
    var departureCity = currentLeg.departure.city.name ? currentLeg.departure.city.name : currentLeg.departure.name;
    var arrivalCity = currentLeg.arrival.city.name ? currentLeg.arrival.city.name : currentLeg.arrival.name;
    return (React.createElement("div", { className: css.calendar },
        React.createElement("div", { className: css.header }, (_c = renderTitle === null || renderTitle === void 0 ? void 0 : renderTitle({
            legNumber: legId + 1,
            departureCity: departureCity,
            arrivalCity: arrivalCity
        })) !== null && _c !== void 0 ? _c : (React.createElement("div", { className: css.title },
            React.createElement("div", { className: css.legNumber }, legId + 1),
            React.createElement("span", { className: css.title__text },
                t('Select flight'),
                "\u00A0"),
            React.createElement("span", { className: css.title__cities },
                React.createElement("span", null, departureCity),
                planeIcon,
                React.createElement("span", null, arrivalCity)))),
            React.createElement("div", { className: css.tabs },
                searchMinPrice && currentLeg.id === 0 && (React.createElement(OtherDates, { searchParameters: searchParameters, startNewSearch: startNewSearch, alternativePrices: alternativePrices })),
                React.createElement(ButtonBase, { onClick: function () { return setActiveTab(TabsList.Monthly); }, className: css.diagram },
                    t('Show price diagram'),
                    diagramIcon))),
        searchParameters.ffpMode && payByMiles && ffpInfo && (React.createElement(FfpInfoNotice, { className: css.ffpInfo, ffpInfo: ffpInfo, onRemoveCard: onRemoveCard, onPayByMoney: onPayByMoney })),
        React.createElement("div", { className: css.content },
            React.createElement(WeekCalendar, { hidePrice: hidePrices, searchParameters: searchParameters, legId: !!searchParameters.segments[legId] ? legId : 0, startNewSearch: startNewSearch, alternativePrices: alternativePrices, allowSelectWithoutPrice: allowSelectWithoutPrice }),
            React.createElement(ThemeProvider, { value: {
                    Modal: {
                        Modal: {
                            closeIcon: css.priceGraph__closeModal,
                            modal: css.priceGraph__modal
                        }
                    },
                    PriceGraph: {
                        PriceGraphStyles: {
                            container: css.priceGraph__content,
                            calendar: css.priceGraph
                        }
                    }
                } },
                React.createElement(Modal, { classes: { root: css.priceGraph__modal }, open: isPriceGraphOpen, onClose: handleModalClose },
                    React.createElement(PriceGraph, { searchParameters: searchParameters, legId: legId, startNewSearch: startNewSearch }))))));
};
export default Calendar;
