import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { WarningRounded } from '../../Icons';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import Button from '../../Button';
import ModalConfirm from '../../ModalConfirm/components/ModalConfirm';
var FfpInfoNotice = function (_a) {
    var className = _a.className, ffpInfo = _a.ffpInfo, onRemoveCard = _a.onRemoveCard, onPayByMoney = _a.onPayByMoney;
    var css = useTheme('FfpInfoNotice').FfpInfoNotice;
    var t = useTranslation('Results').t;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var onOpenModal = function () { return setIsOpen(true); };
    var onCloseModal = function () { return setIsOpen(false); };
    var onRemoveCardHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, onRemoveCard()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.icon }, WarningRounded),
        React.createElement("div", { className: css.text },
            React.createElement("span", { className: css.accent },
                t('Redeem miles'),
                ":"),
            " ",
            t('Card'),
            " ",
            ffpInfo.cardNumber,
            ",",
            ' ',
            React.createElement("span", { className: css.status }, ffpInfo.currentLevel),
            ",",
            React.createElement(PriceInMiles, { amount: ffpInfo.numberOfMiles })),
        React.createElement("div", { className: css.actions },
            React.createElement(Button, { className: css.button, onClick: onOpenModal, variant: 'text' }, t('Change card')),
            React.createElement(Button, { className: css.button, onClick: onPayByMoney, variant: 'text' }, t('Pay by money'))),
        React.createElement(ModalConfirm, { isOpen: isOpen, onClose: onCloseModal, title: t('You sure?'), confirmBtn: t('Yes'), onConfirm: onRemoveCardHandler, cancelBtn: t('No'), onCancel: isLoading ? null : onCloseModal }, t('Delete card from your account?'))));
};
export default FfpInfoNotice;
