import { __awaiter, __rest } from "tslib";
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress } from '../../../Checkout/store/order/selectors';
import { getServiceDialogType } from './utils';
import { isServicesSaving } from '../../../Checkout/store/servicesIsSaving/selectors';
import { useAnimalService } from '../../../CheckoutAdditionalService/components/hooks';
import { useSmsNotifications } from '../../../CheckoutAdditionalService/components/SmsNotifications/SmsNotifications.hook';
import { isVipService } from '../../utils';
import { capitalizeFirstLetter, getAllSegmentsServicePrice, getPassengerLabel, getPassengerNames } from '../../../utils';
import PassengerPriceControl from '../../../CheckoutAdditionalService/components/PassengerPriceControl/PassengerPriceControl';
import * as React from 'react';
import PassengerPrices from '../../../CheckoutAdditionalService/components/PassengerPrices/PassengerPrices';
import { useCardExtraCtx } from '../CardExtra.context';
export function useCardExtraModal({ openServiceType, passengerPrices, isDifferentPrices, onClose }) {
    var _a, _b, _c, _d, _e, _f;
    const { t, i18n } = useTranslation('Checkout');
    const { order, segments, travellers, passengers, selectedServices, type, gdsServiceType, additionalServicesByType, isCanModifyServices, onSaveServicesRequest, onSaveServices } = useCardExtraCtx();
    const aeroexpress = useSelector(getAeroexpress);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const openServiceDialogType = useMemo(() => getServiceDialogType(openServiceType), [openServiceType]);
    const passengerLabels = useMemo(() => {
        const labelMap = {};
        const passengersMap = {};
        passengers.forEach(passenger => {
            var _a;
            const passengerType = (_a = passenger.supplierTravellerType) !== null && _a !== void 0 ? _a : passenger.type;
            if (!passengersMap.hasOwnProperty(passengerType)) {
                passengersMap[passengerType] = 0;
            }
            const passengerIndex = ++passengersMap[passengerType];
            const { firstName, lastName } = getPassengerNames(passenger);
            if (firstName && lastName) {
                labelMap[passenger.id] = [lastName, firstName].map(capitalizeFirstLetter).join(' ');
            }
            else {
                labelMap[passenger.id] = `${getPassengerLabel(passenger)} ${passengerIndex}`;
            }
        });
        return labelMap;
    }, [passengers]);
    const smsTravellers = useMemo(() => travellers.filter(traveller => traveller.type !== 'INF'), [travellers]);
    const serviceDialogModel = useMemo(() => {
        return {
            order: order,
            passengers,
            passengerLabels,
            openedModal: openServiceDialogType,
            onClose
        };
    }, [order, passengers, passengerLabels, openServiceDialogType, onClose]);
    const onActionClose = useCallback((request) => (...args) => __awaiter(this, void 0, void 0, function* () {
        request(...args);
        onClose();
    }), [onClose]);
    let servicePopupModel = null;
    if (type === 'animal') {
        const baggageServices = additionalServicesByType.gdsServices.services;
        const model = useAnimalService(baggageServices, travellers);
        const petPopupProps = {
            segments,
            travellers,
            services: model.servicesByRfisc,
            isLoading: isOrderServicesSaving,
            readOnly: !isCanModifyServices,
            onConfirm: onActionClose(model.onSave),
            onClear: model.onClear,
            onClose
        };
        servicePopupModel = Object.assign({}, petPopupProps);
    }
    else if (type === 'transfer') {
        const aeroexpressPopupProps = {
            trips: aeroexpress.trips,
            selectedTrips: aeroexpress.selectedTrips,
            onSubmit: onSaveServicesRequest,
            onClose
        };
        servicePopupModel = Object.assign({}, aeroexpressPopupProps);
    }
    else if (type === 'insurance') {
        const insurancePopupProps = {
            travellers,
            description: (_c = (_b = (_a = additionalServicesByType.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description,
            insurances: (_d = additionalServicesByType.insurances) === null || _d === void 0 ? void 0 : _d.insurancePrograms,
            selectedInsurances: (_e = additionalServicesByType.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances,
            onSave: onSaveServicesRequest,
            onClose
        };
        servicePopupModel = Object.assign({}, insurancePopupProps);
    }
    else if (type === 'sms') {
        const smsServices = additionalServicesByType.gdsServices
            .services;
        const { onSubmit } = useSmsNotifications(smsServices, travellers, order.id, onClose);
        const smsPopupProps = {
            smsNotificationId: smsServices[0].id,
            title: i18n.exists('Checkout:SmsPopup_title') ? i18n.t('Checkout:SmsPopup_title') : smsServices[0].name,
            description: i18n.exists('Checkout:SmsPopup_description')
                ? i18n.t('Checkout:SmsPopup_description')
                : smsServices[0].description,
            travellers: smsTravellers,
            customerValues: order.customer.values,
            price: getAllSegmentsServicePrice(smsServices[0]),
            onClose,
            onSubmit
        };
        servicePopupModel = Object.assign({}, smsPopupProps);
    }
    else if (isVipService(type)) {
        const vipServices = additionalServicesByType.gdsServices.services;
        const headerTitle = {
            'business-lounge': t('Business lounge'),
            'priority-boarding': t('Priority boarding'),
            'baggage-delivery': t('Baggage delivery'),
            'capsule-hotel': t('Capsule hotel')
        };
        const controlType = {
            'business-lounge': 'checkbox',
            'priority-boarding': 'checkbox',
            'baggage-delivery': 'counter',
            'capsule-hotel': 'checkbox'
        };
        const isCustomControl = type === 'business-lounge' || type === 'priority-boarding' || type === 'capsule-hotel';
        const showTotalPrice = type !== 'baggage-delivery';
        const showFromPrice = type !== 'baggage-delivery';
        const vipServiceSelectProps = {
            segments,
            passengers,
            selectedServices,
            header: (_f = headerTitle[type]) !== null && _f !== void 0 ? _f : '',
            services: vipServices,
            subheader: React.createElement(PassengerPrices, { prices: passengerPrices, isDifferentPrices: isDifferentPrices }),
            renderPassenger: isCustomControl
                ? (_a) => {
                    var _b;
                    var { passenger } = _a, props = __rest(_a, ["passenger"]);
                    return (React.createElement(PassengerPriceControl, Object.assign({}, props, { passenger: passenger, serviceType: gdsServiceType, price: (_b = vipServices.find(service => service.id === passenger.serviceId)) === null || _b === void 0 ? void 0 : _b.price })));
                }
                : undefined,
            type: controlType[type],
            showTotalPrice,
            showFromPrice,
            readOnly: !isCanModifyServices,
            onSave: servicesToSave => {
                onSaveServices(servicesToSave);
            },
            onClose
        };
        servicePopupModel = Object.assign({}, vipServiceSelectProps);
    }
    const isFullscreenDialog = useMemo(() => {
        return type === 'seats' || type === 'baggage' || type === 'meal';
    }, [type]);
    const maxWidth = useMemo(() => {
        if (type === 'animal' ||
            type === 'business-lounge' ||
            type === 'priority-boarding' ||
            type === 'baggage-delivery' ||
            type === 'loyalty') {
            return 'sm';
        }
        else if (type === 'insurance' || type === 'capsule-hotel' || type === 'sms') {
            return 'md';
        }
        return false;
    }, [type]);
    return {
        // data
        serviceDialogModel,
        servicePopupModel,
        isFullscreenDialog,
        maxWidth
    };
}
