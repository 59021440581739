import * as React from 'react';
import { useTheme } from '../../../../../theme';
import CardExtra, { CheckinCardExtrasProvider } from '../../../../../CardExtra';
const AdditionalServices = () => {
    const { AdditionalServices: theme } = useTheme('Checkin');
    return (React.createElement(CheckinCardExtrasProvider, null,
        React.createElement("div", { className: theme.services },
            React.createElement(CardExtra, { type: "insurance" }),
            React.createElement(CardExtra, { type: "business-lounge" }),
            React.createElement(CardExtra, { type: "priority-boarding" }))));
};
export default AdditionalServices;
