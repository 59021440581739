import { __awaiter, __rest } from "tslib";
import { useCallback, useMemo, useState, useEffect } from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getAllSegmentsServicePrice, getMinPrice, getTotalPrice } from '../utils';
import { useCartPrices } from '../Cart/hooks';
import { canBeModifyService, extraGdsServiceToServicePayload, filterGdsServicesByType, getCartPriceKeyByType, getGdsServiceTypeByServiceType, getPassengerPrices, getServicesPrices, getServiceVisible, getUnconfirmedServices, isSingleService as getIsSingleService } from './utils';
import { useConfig } from '../context';
import { useCardExtras } from './CardExtras.context';
import { WEBSKY_BAGGAGE_SERVICE_OPEN_EVENT, WEBSKY_SERVICE_OPEN_EVENT } from '../Checkout/constants';
import { SelectedServiceType } from '../AIPurchasePredictionService/types';
export function useCardExtra({ type, insuranceCodes }) {
    // Hooks
    const { global: { companyInfo: { loyaltyProgramAccountUrl } } } = useConfig();
    const { order, flight, segments, travellers, additionalServices, passengers, selectedServices, brandIncludedBaggage, userInfo, updateUser, isCheckinAvailable } = useCardExtras();
    const cardExtraService = useCardExtraService({ type, insuranceCodes });
    const cardExtraDisclosure = useCardExtraDisclosure(type);
    const cardExtraPrices = useCardExtraPrices({
        type,
        insuranceCodes,
        additionalServices: cardExtraService.additionalServicesByType
    });
    // Side effects
    const onEventHandler = useCallback((event) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const { type: eventServiceType, subType: eventServiceSubType, insuranceCode: eventServiceInsuranceCode } = event.detail.service || {};
        if (eventServiceType === cardExtraService.gdsServiceType ||
            (type === 'business-lounge' &&
                eventServiceType === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge)) {
            if (!((_b = (_a = cardExtraService.additionalServicesByType.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
                return;
            }
            if (eventServiceSubType) {
                const serviceSubTypeSet = new Set();
                (_d = (_c = cardExtraService.additionalServicesByType.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.forEach(service => {
                    if (service === null || service === void 0 ? void 0 : service.baggageType) {
                        // BaggageService
                        const baggageService = service;
                        serviceSubTypeSet.add(baggageService.baggageType);
                    }
                    else if (service === null || service === void 0 ? void 0 : service.subType) {
                        // Vip service
                        const vipService = service;
                        serviceSubTypeSet.add(vipService.subType);
                    }
                });
                if (!serviceSubTypeSet.size || !serviceSubTypeSet.has(eventServiceSubType)) {
                    return;
                }
            }
        }
        else if (type === 'insurance' && eventServiceType === SelectedServiceType.Insurance) {
            if (!((_f = (_e = cardExtraService.additionalServicesByType.insurances) === null || _e === void 0 ? void 0 : _e.insurancePrograms) === null || _f === void 0 ? void 0 : _f.length) ||
                cardExtraService.isSingleService) {
                return;
            }
            const insuranceCodeSet = new Set();
            (_h = (_g = cardExtraService.additionalServicesByType.insurances) === null || _g === void 0 ? void 0 : _g.insurancePrograms) === null || _h === void 0 ? void 0 : _h.forEach(insurance => {
                insuranceCodeSet.add(insurance.code);
            });
            const insuranceCode = eventServiceInsuranceCode ||
                ((_o = (_m = (_l = (_k = (_j = additionalServices.insurances) === null || _j === void 0 ? void 0 : _j.insuranceProgramGroups) === null || _k === void 0 ? void 0 : _k.find(group => { var _a; return ((_a = group.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) > 1; })) === null || _l === void 0 ? void 0 : _l.insurancePrograms) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.code);
            if (!insuranceCodeSet.size || !insuranceCodeSet.has(insuranceCode)) {
                return;
            }
        }
        else {
            return;
        }
        cardExtraDisclosure.onOpenModal();
        if (type === 'baggage' && eventServiceSubType) {
            const event = new CustomEvent(WEBSKY_BAGGAGE_SERVICE_OPEN_EVENT, {
                detail: { type: eventServiceSubType }
            });
            setTimeout(() => document.dispatchEvent(event));
        }
    }, [cardExtraService.gdsServiceType, cardExtraService.additionalServicesByType, cardExtraDisclosure.onOpenModal]);
    useEffect(() => {
        document.addEventListener(WEBSKY_SERVICE_OPEN_EVENT, onEventHandler);
        return () => document.removeEventListener(WEBSKY_SERVICE_OPEN_EVENT, onEventHandler);
    }, []);
    // Methods
    const onSaveServicesRequest = useCallback((serviceIds) => __awaiter(this, void 0, void 0, function* () {
        const data = yield cardExtraService.onSaveServicesRequest(serviceIds);
        cardExtraDisclosure.onCloseModal();
        return data;
    }), [cardExtraService.onSaveServicesRequest, cardExtraDisclosure.onCloseModal]);
    const onToggleService = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (cardExtraService.isSingleService) {
            if (type === 'insurance') {
                const insuranceCode = (_a = cardExtraService.additionalServicesByType.insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.code;
                const isSelected = (_b = additionalServices.insurances.selectedInsurances) === null || _b === void 0 ? void 0 : _b.some(insurance => insurance.insuranceProgram.code === insuranceCode);
                if (isSelected) {
                    yield cardExtraService.onRemoveServices([{ code: insuranceCode }]);
                }
                else {
                    yield cardExtraService.onAddServices([{ code: insuranceCode }]);
                }
            }
            else if (type === 'transfer') {
                const tripId = cardExtraService.additionalServicesByType.transfer.aeroexpress.trips[0].id;
                const isSelected = (_d = (_c = cardExtraService.additionalServicesByType.transfer.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.some(trip => trip.id === tripId);
                if (isSelected) {
                    yield cardExtraService.onRemoveServices([{ id: tripId }]);
                }
                else {
                    yield cardExtraService.onAddServices([{ id: tripId }]);
                }
            }
        }
    }), [type, additionalServices, cardExtraService.isSingleService, cardExtraService.additionalServicesByType]);
    const onActionClose = useCallback((request) => (gdsServices) => __awaiter(this, void 0, void 0, function* () {
        yield request(gdsServices);
        cardExtraDisclosure.onCloseModal();
    }), [cardExtraDisclosure.onCloseModal]);
    const onViewLoyaltyStatus = useCallback(() => {
        const loyaltyUrl = loyaltyProgramAccountUrl !== null && loyaltyProgramAccountUrl !== void 0 ? loyaltyProgramAccountUrl : '/account/loyalty';
        window.open(loyaltyUrl, '_blank');
    }, [loyaltyProgramAccountUrl]);
    return {
        // context
        order,
        flight,
        segments,
        travellers,
        additionalServices,
        passengers,
        selectedServices,
        brandIncludedBaggage,
        userInfo,
        updateUser,
        isCheckinAvailable,
        // data
        type,
        additionalServicesByType: cardExtraService.additionalServicesByType,
        unconfirmedServices: cardExtraService.unconfirmedServices,
        gdsServiceType: cardExtraService.gdsServiceType,
        showService: cardExtraService.showService,
        isSingleService: cardExtraService.isSingleService,
        isCanModifyServices: cardExtraService.isCanModifyServices,
        isLoading: cardExtraService.isLoading,
        cardExtraPrices,
        cardExtraDisclosure,
        // methods
        onSaveServicesRequest,
        onToggleService,
        onSaveServices: onActionClose(cardExtraService.onSaveServices),
        onAddServices: onActionClose(cardExtraService.onAddServices),
        onRemoveServices: onActionClose(cardExtraService.onRemoveServices),
        onRemoveUnconfirmedServices: cardExtraService.onRemoveUnconfirmedServices,
        onViewLoyaltyStatus
    };
}
export function useCardExtraService({ type, insuranceCodes }) {
    // Hooks
    const { global: { servicesAvailable } } = useConfig();
    const _a = useCardExtras(), { order, flight, travellers, additionalServices, passengers, selectedServices, brandIncludedBaggage } = _a, cardExtras = __rest(_a, ["order", "flight", "travellers", "additionalServices", "passengers", "selectedServices", "brandIncludedBaggage"]);
    // Data
    const gdsServiceType = useMemo(() => getGdsServiceTypeByServiceType(type), [type]);
    const additionalServicesByType = useMemo(() => {
        return filterGdsServicesByType(type, flight, additionalServices, insuranceCodes, servicesAvailable.animal);
    }, [type, flight, additionalServices, insuranceCodes, servicesAvailable.animal]);
    const unconfirmedServices = useMemo(() => {
        return getUnconfirmedServices(gdsServiceType, travellers, selectedServices);
    }, [gdsServiceType, travellers, selectedServices]);
    const isSingleService = useMemo(() => {
        return getIsSingleService(type, additionalServicesByType);
    }, [type, additionalServicesByType]);
    const isCanModifyServices = useMemo(() => {
        var _a, _b;
        if (!((_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return false;
        }
        return additionalServices.gdsServices.services.some(service => canBeModifyService(service, travellers));
    }, [additionalServices, travellers]);
    const showService = useMemo(() => {
        var _a;
        const isSupportFfp = !!((_a = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _a === void 0 ? void 0 : _a.ffpSupported);
        return getServiceVisible(type, flight, travellers, additionalServicesByType, isCanModifyServices, isSupportFfp, {
            includedBaggageAvailable: brandIncludedBaggage.length > 0,
            animalAvailable: servicesAvailable.animal
        });
    }, [
        type,
        order,
        flight,
        travellers,
        additionalServicesByType,
        brandIncludedBaggage,
        servicesAvailable.animal,
        isCanModifyServices
    ]);
    // Methods
    const onSaveServicesRequest = useCallback((serviceIds) => __awaiter(this, void 0, void 0, function* () {
        const params = {
            id: order.id
        };
        if (type === 'insurance') {
            params.insurances = [
                {
                    selectedPrograms: serviceIds,
                    selectedProgramsByTravellers: travellers.map(traveller => ({
                        travellerId: traveller.id,
                        travellerDocumentNumber: '',
                        programCodes: serviceIds
                    }))
                }
            ];
        }
        else if (type === 'transfer') {
            params.aeroexpress = {
                selectedTrips: serviceIds
            };
        }
        return cardExtras.onSaveServicesRequest(params);
    }), [type, order.id, travellers, additionalServices, cardExtras.onSaveServicesRequest]);
    const onActionServices = useCallback((actionType, servicesToSave, gdsServicesRequest) => __awaiter(this, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f;
        if (type === 'insurance' || type === 'transfer') {
            const serviceIds = new Set();
            if (type === 'insurance') {
                const insuranceServices = servicesToSave;
                (_c = (_b = additionalServices.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.forEach(insurance => serviceIds.add(insurance.insuranceProgram.code));
                insuranceServices.forEach(service => {
                    if (actionType === 'add') {
                        serviceIds.add(service.code);
                    }
                    else {
                        serviceIds.delete(service.code);
                    }
                });
            }
            else if (type === 'transfer') {
                const transferServices = servicesToSave;
                (_f = (_e = (_d = additionalServices.transfer) === null || _d === void 0 ? void 0 : _d.aeroexpress) === null || _e === void 0 ? void 0 : _e.selectedTrips) === null || _f === void 0 ? void 0 : _f.forEach(trip => serviceIds.add(trip.id));
                transferServices.forEach(service => {
                    if (actionType === 'add') {
                        serviceIds.add(service.id);
                    }
                    else {
                        serviceIds.delete(service.id);
                    }
                });
            }
            yield onSaveServicesRequest([...serviceIds]);
        }
        else {
            const gdsServices = servicesToSave;
            const servicesPayload = [];
            gdsServices.forEach(service => {
                service.segmentIds.map(segmentId => {
                    const servicePayload = extraGdsServiceToServicePayload(Object.assign(Object.assign({}, service), { segmentIds: [segmentId] }), gdsServiceType);
                    servicesPayload.push(servicePayload);
                });
            });
            gdsServicesRequest(servicesPayload);
        }
    }), [type, gdsServiceType, onSaveServicesRequest]);
    const onSaveServices = useCallback((gdsServices) => {
        const servicesPayload = gdsServices.map(service => extraGdsServiceToServicePayload(service, gdsServiceType));
        return cardExtras.onSaveServices(servicesPayload);
    }, [cardExtras.onSaveServices]);
    const onAddServices = useCallback((servicesToAdd) => {
        return onActionServices('add', servicesToAdd, cardExtras.onAddServices);
    }, [onActionServices, cardExtras.onAddServices]);
    const onRemoveServices = useCallback((servicesToRemove) => {
        return onActionServices('remove', servicesToRemove, cardExtras.onRemoveServices);
    }, [onActionServices, cardExtras.onRemoveServices]);
    const onRemoveUnconfirmedServices = useCallback(() => cardExtras.onRemoveServices(unconfirmedServices), [
        unconfirmedServices,
        cardExtras.onRemoveServices
    ]);
    return {
        // data
        additionalServicesByType,
        unconfirmedServices,
        gdsServiceType,
        isSingleService,
        isCanModifyServices,
        isLoading: cardExtras.isLoading,
        showService,
        // methods
        onSaveServicesRequest,
        onSaveServices,
        onAddServices,
        onRemoveServices,
        onRemoveUnconfirmedServices
    };
}
export function useCardExtraPrices({ type, insuranceCodes, additionalServices }) {
    // Hooks
    const { travellers, flight } = useCardExtras();
    const prices = useCartPrices();
    // Data
    const isDifferentPrices = useMemo(() => {
        const servicesPrices = getServicesPrices(type, additionalServices);
        return new Set(servicesPrices.map(price => price.amount)).size > 1;
    }, [type, additionalServices]);
    const isAllSegmentsAvailableOnCheckin = useMemo(() => {
        return flight.seatMap.segments.every(segment => segment.isSeatsAvailableInCheckinOnly === true);
    }, [flight.seatMap]);
    const passengerPrices = useMemo(() => {
        return getPassengerPrices(type, additionalServices, travellers, isDifferentPrices);
    }, [type, additionalServices, travellers, isDifferentPrices]);
    const totalPrice = useMemo(() => {
        var _a, _b;
        if (type === 'insurance' && (insuranceCodes === null || insuranceCodes === void 0 ? void 0 : insuranceCodes.length)) {
            const selectedInsuranceMap = new Map();
            if ((_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length) {
                additionalServices.insurances.selectedInsurances.forEach(insurance => {
                    const insuranceCode = insurance.insuranceProgram.code;
                    if (insuranceCodes.includes(insuranceCode) && !selectedInsuranceMap.has(insuranceCode)) {
                        selectedInsuranceMap.set(insuranceCode, insurance);
                    }
                });
            }
            return getTotalPrice([...selectedInsuranceMap.values()], insurance => insurance.insuranceProgram.totalPrice);
        }
        const priceKey = getCartPriceKeyByType(type);
        if (!priceKey) {
            return null;
        }
        return prices[priceKey];
    }, [type, additionalServices, prices]);
    const minPrice = useMemo(() => {
        var _a, _b, _c;
        if (type === 'seats') {
            if (isAllSegmentsAvailableOnCheckin) {
                return null;
            }
            const seatPricesMap = new Map();
            if ((_b = (_a = flight.seatMap) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length) {
                flight.seatMap.segments.forEach(segment => {
                    var _a;
                    (_a = segment.decks) === null || _a === void 0 ? void 0 : _a.forEach(deck => {
                        var _a;
                        (_a = deck.rows) === null || _a === void 0 ? void 0 : _a.forEach(row => {
                            var _a;
                            (_a = row.seats) === null || _a === void 0 ? void 0 : _a.forEach(seat => {
                                const price = seat === null || seat === void 0 ? void 0 : seat.price;
                                const amount = price === null || price === void 0 ? void 0 : price.amount;
                                if ((seat === null || seat === void 0 ? void 0 : seat.isAvailable) && amount !== undefined && !seatPricesMap.has(amount)) {
                                    seatPricesMap.set(amount, price);
                                }
                            });
                        });
                    });
                });
            }
            return getMinPrice([...seatPricesMap.values()], price => price);
        }
        if (type === 'sms') {
            return getAllSegmentsServicePrice((_c = additionalServices.gdsServices) === null || _c === void 0 ? void 0 : _c.services[0]);
        }
        return getMinPrice(passengerPrices, price => price.price);
    }, [type, passengerPrices, flight, isAllSegmentsAvailableOnCheckin]);
    return {
        isDifferentPrices,
        passengerPrices,
        totalPrice,
        minPrice
    };
}
export function useCardExtraDisclosure(type) {
    const [openServiceType, setOpenServiceType] = useState();
    const onToggleModal = useCallback(() => {
        setOpenServiceType(state => (state ? undefined : type));
    }, [type, setOpenServiceType]);
    const onOpenModal = useCallback(() => {
        setOpenServiceType(type);
    }, [type, setOpenServiceType]);
    const onCloseModal = useCallback(() => {
        setOpenServiceType(undefined);
    }, [setOpenServiceType]);
    return {
        // data
        openServiceType,
        isOpenModal: !!openServiceType,
        // methods
        setOpenServiceType,
        onToggleModal,
        onOpenModal,
        onCloseModal
    };
}
export function useCardExtraIconSource(type) {
    const { global: { companyInfo: { iataCode } } } = useConfig();
    return {
        src: `https://cdn.websky.aero/content/frontend/images/${iataCode}/CardExtra/${type}.svg`,
        fallbackSrc: `https://cdn.websky.aero/content/frontend/images/ZZ/CardExtra/${type}.svg`
    };
}
