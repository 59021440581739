import { ServiceType as ServiceDialogType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
export function getServiceDialogType(type) {
    switch (type) {
        case 'baggage':
        case 'baggage-delivery':
        case 'animal':
            return ServiceDialogType.Baggage;
        case 'meal':
            return ServiceDialogType.Meal;
        case 'seats':
            return ServiceDialogType.Seats;
        case 'transfer':
            return ServiceDialogType.Transfer;
        case 'insurance':
            return ServiceDialogType.Insurance;
        default:
            return null;
    }
}
