import * as React from 'react';
import { useTheme } from '@websky/core/src/theme';
import { arrowIcon, emptyCard } from '@websky/core/src/PaymentForm/Icons';
import { ButtonBase } from '@material-ui/core';
import { PaymentType } from '@websky/core/src/PaymentForm/types';
import { useTranslation } from 'react-i18next';
import css from './Gateway.css';
import cn from 'classnames';
var Gateway = function (_a) {
    var type = _a.type, header = _a.header, description = _a.description, onClick = _a.onClick, paymentType = _a.paymentType;
    var RootCss = useTheme('PaymentForm').Gateway;
    var t = useTranslation('PaymentForm').t;
    var isShowConvenientTag = paymentType === PaymentType.Fps;
    return (React.createElement(ButtonBase, { className: cn(RootCss.selector, css.wrapper), onClick: onClick, "data-payment-type": paymentType },
        React.createElement("div", { className: RootCss.header }, header !== null && header !== void 0 ? header : emptyCard,
            React.createElement("span", { className: RootCss.header__arrow },
                React.createElement("span", null, type),
                " ",
                React.createElement("span", { className: RootCss.arrow }, arrowIcon))),
        description && React.createElement("p", { className: RootCss.description }, description),
        isShowConvenientTag && React.createElement("div", { className: css.tag }, t('Convenient!'))));
};
export default Gateway;
