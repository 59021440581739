import { __awaiter } from "tslib";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderAdditionalServiceGdsServiceServiceType, useAddTravellersDataMutation, useUpdateOrderMutation } from '@websky/graphql';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { useOrderService } from '../hooks';
import { getChangedServicesToSave, getUpdatedTravellersPhones } from './utils';
import { setServicesIsSaving, setServicesSaved } from '../../../Checkout/store/servicesIsSaving/actions';
import { isServicesSaving } from '../../../Checkout/store/servicesIsSaving/selectors';
export const useSmsNotifications = (services, travellers, orderId, onClose) => {
    const dispatch = useDispatch();
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const { onSaveHandler, onRemoveHandler } = useOrderService(services, OrderAdditionalServiceGdsServiceServiceType.Vip, true);
    const [addTravellersDataMutation] = useAddTravellersDataMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [updateTravellers, setUpdateTravellers] = useState([]);
    useEffect(() => {
        // a workaround for displaying the loader during order updates
        if (!isOrderServicesSaving && updateTravellers.length) {
            setTimeout(() => onUpdateTravellers(updateTravellers));
            setUpdateTravellers([]);
        }
    }, [isOrderServicesSaving, updateTravellers]);
    const onUpdateTravellers = (travellersData) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setServicesIsSaving());
        try {
            yield addTravellersDataMutation({
                variables: {
                    variables: {
                        orderId,
                        travellersData
                    }
                }
            });
            const { data } = yield updateOrder({ variables: { id: orderId } });
            if (data === null || data === void 0 ? void 0 : data.UpdateOrder) {
                dispatch(fillOrder(data.UpdateOrder));
            }
        }
        catch (e) {
            console.log(e);
        }
        dispatch(setServicesSaved());
    });
    const onSubmit = (values, form) => __awaiter(void 0, void 0, void 0, function* () {
        onClose();
        const dirtyFields = form.getState().dirtyFields;
        const { servicesToAdd, servicesToRemove } = getChangedServicesToSave(values, services[0], dirtyFields);
        if (servicesToRemove.length) {
            onRemoveHandler(servicesToRemove);
        }
        if (servicesToAdd.length) {
            onSaveHandler(servicesToAdd);
        }
        setUpdateTravellers(getUpdatedTravellersPhones(values, dirtyFields, travellers));
    });
    return { onSubmit };
};
