import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CartItemOptionAdapter from '../../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import Button from '../../../BaseComponents/Button';
import { useConfig } from '../../../context';
import { getMilesForOrder } from '../../../Checkout/store/order/selectors';
import { useCardExtraSummary } from './cardExtraSummary.hook';
import { isVipService } from '../../utils';
import { useCardExtraCtx } from '../CardExtra.context';
const CardExtraSummary = ({ children }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('CardExtra');
    const { global: { companyInfo: { loyaltyProgramName } } } = useConfig();
    const milesBonus = useSelector(getMilesForOrder);
    const { type, flight, additionalServicesByType, userInfo, isLoading, isCheckinAvailable } = useCardExtraCtx();
    const model = useCardExtraSummary();
    const content = useMemo(() => {
        if (type === 'loyalty' && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.userFfpInfo)) {
            return (React.createElement(React.Fragment, null, milesBonus > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("span", null, t('MilesAwarded', {
                    number: milesBonus,
                    miles: t('Miles', { count: milesBonus })
                })),
                React.createElement("span", null, t('MilesWillBeCredited')))) : (React.createElement("span", null, t('MilesNotCredited')))));
        }
        if (!model.items.groups.length && !model.items.allSegmentsGroups.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            !!model.items.allSegmentsGroups.length && (React.createElement(ServiceSummaryDivider, null, model.items.allSegmentsGroups.map((group, index) => (React.createElement(CartItemOptionAdapter, { option: group, key: index }))))),
            !!model.items.groups.length && (React.createElement(ServiceSummaryDivider, null, model.items.groups.map((group, index) => (React.createElement(CartItemOptionAdapter, { option: group, key: index })))))));
    }, [type, userInfo, model.items]);
    const isSeatsAvailableInCheckinOnly = useMemo(() => {
        var _a;
        return (_a = flight.seatMap.segments) === null || _a === void 0 ? void 0 : _a.every(segment => segment.isSeatsAvailableInCheckinOnly);
    }, [flight]);
    const isSelected = useMemo(() => {
        if (type === 'loyalty') {
            return !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userFfpInfo);
        }
        return !!content;
    }, [type, userInfo, content]);
    const title = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (type === 'loyalty') {
            return loyaltyProgramName;
        }
        else if (type === 'insurance' && ((_b = (_a = additionalServicesByType.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) === 1) {
            return (_e = (_d = (_c = additionalServicesByType.insurances) === null || _c === void 0 ? void 0 : _c.insurancePrograms) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.name;
        }
        return null;
    }, [
        type,
        additionalServicesByType,
        (_a = model.items.groups[0]) === null || _a === void 0 ? void 0 : _a.items,
        (_b = model.items.allSegmentsGroups[0]) === null || _b === void 0 ? void 0 : _b.items,
        loyaltyProgramName
    ]);
    const description = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (!isSelected) {
            if (type === 'insurance') {
                return (_c = (_b = (_a = additionalServicesByType.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description;
            }
            else if (isVipService(type)) {
                return (_f = (_e = (_d = additionalServicesByType.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.description;
            }
        }
        if (type === 'seats' && isSeatsAvailableInCheckinOnly) {
            return isCheckinAvailable
                ? t('Seat selection is available in the online registration service')
                : t('You can select seats using the online registration service after issuing tickets');
        }
        const itemDescription = (_j = (_h = (_g = model.items.groups[0]) === null || _g === void 0 ? void 0 : _g.items) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.description;
        const allSegmentsItemDescription = (_m = (_l = (_k = model.items.allSegmentsGroups[0]) === null || _k === void 0 ? void 0 : _k.items) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.description;
        return itemDescription !== null && itemDescription !== void 0 ? itemDescription : allSegmentsItemDescription;
    }, [
        type,
        additionalServicesByType,
        isSelected,
        (_c = model.items.groups[0]) === null || _c === void 0 ? void 0 : _c.items,
        (_d = model.items.allSegmentsGroups[0]) === null || _d === void 0 ? void 0 : _d.items,
        isCheckinAvailable
    ]);
    const additionalControls = useMemo(() => {
        if (type === 'transfer' && isSelected && !isLoading) {
            return (React.createElement(Button, { variant: "optionEdit", onClick: () => window.open('https://aeroexpress.ru', '_blank') }, t('Aeroexpress:View schedule')));
        }
        return null;
    }, [type, isSelected, isLoading]);
    const hideFooterControls = useMemo(() => {
        if (type === 'seats') {
            return isSeatsAvailableInCheckinOnly;
        }
        return false;
    }, [type, isSeatsAvailableInCheckinOnly]);
    return children({
        content,
        title,
        description,
        additionalControls,
        isSelected,
        hideFooterControls
    });
};
export default CardExtraSummary;
