import Value from './components/SearchForm/components/Value/Value';
import BaggageIcon from './components/Baggage/Baggage';
import Calendar from './components/Results/Calendar/Calendar';
import FareGroupIcons from './components/FareGroup/icons';
import FareGroupGridIcons from './components/FareGroup/FareGroupGrid/icons';
import InfoLinksLabel from './components/InfoLinksLabel/InfoLinksLabel';
import CheckoutAdditionalServiceHeader from './components/CheckoutAdditionalService/Header/Header';
import SeatsAdditionalService from './components/CheckoutAdditionalService/Seats/Seats';
import MilesRecoveryMessage from './components/MilesRecoveryMessage/MilesRecoveryMessage';
import CompareFaresIcon from './components/CompareFares/Cell/Cell';
import DescriptionRender from './components/FareLock/DescriptionRender';
import { attention as ResultsChildWithoutAdultNoticeIcon } from './components/Results/ChildWithoutAdultNotice/icons';
import DesktopFlightModal from './components/DesktopFlightModal/DesktopFlightModal';
import Aeroexpress from './components/Aeroexpress/Aeroexpress';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService/CheckoutAdditionalService';
import OrderInsuranceBlockSlotUpsale from '@websky/core/src/Checkout/components/Checkout/OrderInsuranceBlockSlot/OrderInsuranceBlockSlot.upsale';
import Gateway from './components/Gateway/Gateway';
export var nordstarRenderers = {
    SearchFormSegmentValue: Value,
    BaggageIcon: BaggageIcon,
    ResultsCalendar: Calendar,
    renderFareGroupOptionValue: function (availability) { return FareGroupIcons[availability]; },
    renderFareGroupOptionIcon: function (type) { return FareGroupGridIcons[type]; },
    renderRetrieveBookingInputTooltip: function () { return null; },
    InfoLinksLabel: InfoLinksLabel,
    renderCheckoutAdditionalServiceHeader: CheckoutAdditionalServiceHeader,
    CheckoutAdditionalService: CheckoutAdditionalService,
    CheckoutAdditionalServiceSeats: SeatsAdditionalService,
    renderContributionsSeparately: function () { return null; },
    renderSuccessMilesRecoveryMessage: MilesRecoveryMessage,
    Cell: CompareFaresIcon,
    // PaymentFormCards,
    DescriptionRenderers: DescriptionRender,
    ResultsChildWithoutAdultNoticeIcon: function () { return ResultsChildWithoutAdultNoticeIcon; },
    DesktopFlightRouteModal: DesktopFlightModal,
    CheckoutAeroexpress: Aeroexpress,
    CheckoutInsurance: OrderInsuranceBlockSlotUpsale,
    Gateway: Gateway
};
