import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriceGraph from './PriceGraph/PriceGraph';
import WeekCalendar from './WeekCalendar/WeekCalendar';
import { ThemeProvider, useTheme } from '../../../../theme';
import Modal from '../../../../Modal';
import { ButtonBase } from '@material-ui/core';
import OtherDates from '../OtherDates/OtherDates';
import FfpInfoNotice from '../../../../FfpInfoNotice';
import { useConfig } from '../../../../context';
var TabsList;
(function (TabsList) {
    TabsList[TabsList["Weekly"] = 0] = "Weekly";
    TabsList[TabsList["Monthly"] = 1] = "Monthly";
})(TabsList || (TabsList = {}));
const diagramIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 18V4H17V18H15ZM7 18H9V6H7V18ZM3 18H5V12H3V18ZM13 18H11V9H13V18ZM19 18H21V7H19V18Z", fill: "currentColor" })));
const planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 22H10L15 13.5789H20.5C21.33 13.5789 22 12.8737 22 12C22 11.1263 21.33 10.4211 20.5 10.4211H15L10 2H8L10.5 10.4211L5 10.4211L3.5 8.31579H2L3 12L2 15.6842H3.5L5 13.5789L10.5 13.5789L8 22Z", fill: "currentColor" })));
const Calendar = ({ searchParameters, currentLeg, startNewSearch, searchMinPrice, alternativePrices, ffpInfo, onRemoveCard, onPayByMoney, hidePrices = false, renderTitle, allowSelectWithoutPrice }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation('Results');
    const [isPriceGraphOpen, setPriceGraphOpen] = useState(false);
    const { Calendar: css } = useTheme('Results');
    const legId = currentLeg ? currentLeg.id : 0;
    const [activeTab, setActiveTab] = useState(TabsList.Weekly);
    const payByMiles = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.SearchForm.payByMiles;
    useEffect(() => {
        if (activeTab === TabsList.Monthly) {
            setActiveTab(TabsList.Weekly);
            setPriceGraphOpen(true);
        }
    }, [activeTab]);
    const handleModalClose = useCallback(() => {
        setPriceGraphOpen(false);
    }, [activeTab]);
    let departureCity = ((_b = currentLeg.departure) === null || _b === void 0 ? void 0 : _b.name) || '';
    let arrivalCity = ((_c = currentLeg.arrival) === null || _c === void 0 ? void 0 : _c.name) || '';
    if (((_d = currentLeg.departure.city) === null || _d === void 0 ? void 0 : _d.name) && ((_e = currentLeg.arrival.city) === null || _e === void 0 ? void 0 : _e.name)) {
        departureCity = currentLeg.departure.city.name;
        arrivalCity = currentLeg.arrival.city.name;
    }
    if (!departureCity || !arrivalCity) {
        departureCity = currentLeg.departure.iata;
        arrivalCity = currentLeg.arrival.iata;
    }
    return (React.createElement("div", { className: css.calendar },
        React.createElement("div", { className: css.header }, (_f = renderTitle === null || renderTitle === void 0 ? void 0 : renderTitle({
            legNumber: legId + 1,
            departureCity,
            arrivalCity
        })) !== null && _f !== void 0 ? _f : (React.createElement("div", { className: css.title },
            React.createElement("div", { className: css.legNumber }, legId + 1),
            React.createElement("span", { className: css.title__text },
                t('Select flight'),
                "\u00A0"),
            React.createElement("span", { className: css.title__cities },
                React.createElement("span", null, departureCity),
                planeIcon,
                React.createElement("span", null, arrivalCity)))),
            React.createElement("div", { className: css.tabs },
                searchMinPrice && currentLeg.id === 0 && (React.createElement(OtherDates, { searchParameters: searchParameters, startNewSearch: startNewSearch, alternativePrices: alternativePrices })),
                React.createElement(ButtonBase, { onClick: () => setActiveTab(TabsList.Monthly), className: css.diagram },
                    t('Show price diagram'),
                    diagramIcon))),
        searchParameters.ffpMode && payByMiles && ffpInfo && (React.createElement(FfpInfoNotice, { className: css.ffpInfo, ffpInfo: ffpInfo, onRemoveCard: onRemoveCard, onPayByMoney: onPayByMoney })),
        React.createElement("div", { className: css.content },
            React.createElement(WeekCalendar, { hidePrice: hidePrices, searchParameters: searchParameters, legId: !!searchParameters.segments[legId] ? legId : 0, startNewSearch: startNewSearch, alternativePrices: alternativePrices, allowSelectWithoutPrice: allowSelectWithoutPrice }),
            React.createElement(ThemeProvider, { value: {
                    Modal: {
                        Modal: {
                            closeIcon: css.priceGraph__closeModal,
                            modal: css.priceGraph__modal
                        }
                    },
                    PriceGraph: {
                        PriceGraphStyles: {
                            container: css.priceGraph__content,
                            calendar: css.priceGraph
                        }
                    }
                } },
                React.createElement(Modal, { classes: { root: css.priceGraph__modal }, open: isPriceGraphOpen, onClose: handleModalClose },
                    React.createElement(PriceGraph, { searchParameters: searchParameters, legId: legId, startNewSearch: startNewSearch }))))));
};
export default Calendar;
